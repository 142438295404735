var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: _vm.fixBottom },
    [
      _c("Banner", { attrs: { "show-register-dialog": true } }),
      _vm._v(" "),
      _c("SearchInput", {
        attrs: {
          "header-style": _vm.headerStyle,
          keyword: _vm.keyword,
          "login-redirect": "/tupian",
          "login-state": false,
          "show-register-dialog": true,
        },
      }),
      _vm._v(" "),
      _c("main", { staticClass: "main" }, [
        _c(
          "div",
          { class: [_vm.$style.wrapper, "gridCentered"] },
          [
            _c("vMasonry", {
              attrs: {
                config: _vm.masonryConfig,
                items: _vm.$store.state.cache["FETCH_INDEX_DATA"],
                refreshID: _vm.refreshID,
                gapX: 16,
                gapY: 20,
                gridWidth: 236,
                loadDistance: 500,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (props) {
                    return [
                      _c("PinItem", {
                        attrs: {
                          "show-register-dialog": true,
                          item: props.item.data,
                          itemIndex: props.itemIndex,
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.loadBlock },
        [_vm.$store.state.server ? _c("Loading") : _vm._e()],
        1
      ),
      _vm._v(" "),
      !_vm.$store.state.server
        ? _c("MaskBottom", { attrs: { "show-register-dialog": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(_vm.dialogLogin.component, {
        tag: "component",
        attrs: { show: _vm.dialogLogin.show, pageUrl: _vm.pageUrl },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }