<template>
    <div :style=fixBottom>
        <Banner :show-register-dialog=true />
        <SearchInput :header-style="headerStyle" :keyword=keyword :login-redirect="'/tupian'" :login-state="false" :show-register-dialog=true></SearchInput>
        <main class="main">
            <div :class="[$style.wrapper,'gridCentered']" >
                <vMasonry :config="masonryConfig" :items="$store.state.cache[`FETCH_INDEX_DATA`]" :refreshID="refreshID"  :gapX="16" :gapY="20" :gridWidth="236" :loadDistance="500" >
                    <template v-slot:default="props">
                        <PinItem :show-register-dialog=true :item="props.item.data" :itemIndex="props.itemIndex"/>
                    </template>
                </vMasonry>
            </div>
        </main>
        <div :class="$style.loadBlock">
            <Loading v-if="$store.state.server"/>
        </div>
        <MaskBottom v-if="!$store.state.server" :show-register-dialog=true />
        <component :is="dialogLogin.component" :show="dialogLogin.show" :pageUrl="pageUrl"></component>
    </div>
</template>
<script lang=ts>
import axios from 'axios';
import Vue from "vue";
import { DialogIframe, Loading, LoginView } from "bbw-components";
import {StoreUserInfo, BigBigWork, PagePinliteTupian, getDomain} from "bbw-common";
import MixinSearch from "@mixins/search";
import SearchInput from "@components/SearchInput/SearchInput.vue";
import SearchSupply from "@components/SearchSupply/SearchSupply.vue";
import SearchEnd from "@components/SearchEnd/SearchEnd.vue";
import HeaderGlobal from "@components/HeaderGlobal/HeaderGlobal.vue";
import GridPinItem from "@components/GridPinItem/GridPinItem.vue";
import MaskBottom from "@components/MaskBottom/MaskBottom";
import vMasonry from "bbw-masonry";
import {resizeItem} from "@scripts/util";
import {FETCH_INDEX_DATA} from "../../../server/api";
import Banner from "@components/BannerUnlogin/BannerUnlogin";
Vue.use(vMasonry);

export default {
    name:"PageUnlogin",
    asyncData: function( {store, route, context} ){
        if( process?.env?.VUE_ENV == 'server'){
            const res = context.cache.get('FETCH_INDEX_DATA');
            const banner = context.cache.get('FETCH_BANNER_UNLOGIN');
            try {
                const items = res.items.map( ( val ) => {
                    return resizeItem(val);
                })
                store.commit ( `setCache`, { key: `FETCH_INDEX_DATA`, value: items } ) ;
                store.commit ( `setBannerUnlogin`, { ...banner, domain: context.req.hostname } ) ;
                store.commit('setCache', {key:"updateTime", value: new Date().toLocaleString()});
                return Promise.resolve(true);
            }catch (e) {
                store.commit ( `setCache`, { key: `FETCH_INDEX_DATA`, value: [] } ) ;
                store.commit('setCache', {key:"updateTime", value: new Date().toLocaleString()});
                store.commit ( `setDebug`, { key: `UnloginAsyncData`, value: JSON.stringify(e) } ) ;
                return Promise.reject( e );
            }
        }else{
            return Promise.resolve(store.state.cache["FETCH_INDEX_DATA"]);
        }
    },
    components: {
      Banner,
      Loading,
      HeaderGlobal,
      SearchInput,
      PinItem: GridPinItem,
      SearchSupply,
      SearchEnd,
      MaskBottom,
      DialogIframe,
      LoginView
    },
    mixins:[MixinSearch],
    beforeMount() {
      this.pageUrl = `${ window.location.protocol }//${window.location.hostname}${ window.location.port ? ':'+ window.location.port : '' }/tupian`;
    },
    async mounted() {
        try {
            BigBigWork.addListener('OpenDialogLogin', () => {
              this.toggleLoginDialog(true)
            })
            BigBigWork.addListener('closeLoginView', () => {
              this.toggleLoginDialog(false)
            })
            this.refreshID = new Date().getTime();
            //载入完毕后隐藏loading
            this.isSearchEnd = true;
            //注册用户模块
            StoreUserInfo.CreateUserInfoStore(this.$store);
            await this.$store.dispatch('UserInfo/FETCH_USER_INFO');
            //验证是否登录，如果已登录就跳转到登录页
            if(this.$store.getters["UserInfo/userReady"]){
                this.$router.replace({path:'/tupian'})
            }else{
                this.$store.commit('UserInfo/resetUserInfo');
            }
        }catch (e) {
            throw e;
        }
    },
    data:function(){
        return{
            keyword:this.$route.query.kw || "",
            bookmark: null,
            supplyWords: [],
            isSearchEnd: false,
            pageUrl: ``,
            dialogLogin: {
              show: false,
              component: null
            },
            refreshID:0,
            headerStyle:{
                height: "80px"
            }
        }
    },
    methods:{
      async toggleLoginDialog( isShow:boolean = true ){
        if( isShow ){
          if( !this.dialogLogin.component ){
            this.dialogLogin.component = ( await import("bbw-components/src/components/LoginViewPin") ).default;
          }
          this.dialogLogin.show = true;
        }else{
          this.dialogLogin.show = false;
        }
      }
    },
    computed:{
        userInfo(){
            return this.$store.state.UserInfo;
        },
        fixBottom(){
            return this.$store.state.bannerUnlogin.ready ? { 'paddingBottom': '80px'} : { 'paddingBottom': '0px'}
        }
    },
    watch: {
    }
};

</script>

<style lang="scss" module>
    .wrapper{
        margin-left:auto;margin-right: auto;
    }
    .loadBlock{
        position: relative; clear: both; float: none;
        display: flex; justify-content: center; align-items: center;
    }
</style>
